<template>
  <v-container v-if="!_.isEmpty(investorEditable)">
    <div
      v-if="
        investorEditable.investor.type === 'FUND' ||
        (investorEditable.completed_steps &&
          investorEditable.completed_steps.includes('REGISTER_COMPLETED') &&
          !_.isEmpty(investorEditable.investor))
      "
    >
      <!-- Banner header -->
      <Banner
        :title="`Inversor (status: ${investorEditable.investor.status_profile}) ${
          investorEditable.migration_id ? `(ID de migración: ${investorEditable.migration_id})` : ''
        }`"
      >
        <template v-slot:actions>
          <v-btn
            v-if="
              permissionsBannerActions.toInvest.includes(user.role) &&
              investorEditable.status === 'ACTIVE' &&
              investorEditable.investor.status_profile === 'ACTIVE'
            "
            class="ml-5"
            small
            :to="`/inversores/${investorEditable.investor.type.toLowerCase()}/${$route.params.id}/invertir-multiples-proyectos`"
          >
            Invertir proyectos
          </v-btn>
          <BtnInvestProjectBag
            v-if="
              permissionsBannerActions.toInvest.includes(user.role) &&
              investorEditable.status === 'ACTIVE' &&
              investorEditable.investor.status_profile === 'ACTIVE'
            "
            :investor="investor"
          />
          <v-divider vertical inset class="mx-4" />
          <v-btn
            v-if="
              permissionsBannerActions.acceptInvestorConditions.includes(user.role) &&
              investorEditable.investor.type === 'RETAIL' &&
              areGeneralConditionsAccepted
            "
            small
            @click="acceptInvestorConditions({ investor_id: investorEditable.id, general_conditions_date: generalConditionsDate })"
          >
            Aceptar condiciones
          </v-btn>
          <v-btn
            v-if="
              permissionsBannerActions.verifyEmail.includes(user.role) &&
              (!investorEditable.completed_steps || !investorEditable.completed_steps.includes('EMAIL_VERIFIED')) &&
              investorEditable.investor.type === 'RETAIL'
            "
            small
            @click="verifyUserEmail(investorEditable.id)"
          >
            Verificar email
          </v-btn>
          <v-btn
            v-if="permissionsBannerActions.downloadReport.includes(user.role) && investorEditable.investor.type === 'FUND'"
            small
            @click="downloadFundInvestorProjects()"
          >
            <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
            Reporte de inversiones
          </v-btn>
        </template>
      </Banner>

      <!-- Manage automatic reinvestment -->
      <BtnManageAutomaticReinvestment :investor="investorEditable" small class="mt-8" />

      <p class="mt-2 mb-0"><b>Fecha de registro:</b> {{ formatDateL(investorEditable.created_at) }}</p>
      <p>
        <b>Última sesión:</b> {{ investorEditable.last_login_at ? formatDateLWithTime(investorEditable.last_login_at) : "No guardada" }}
      </p>

      <!-- Form profile data -->
      <v-card class="mt-4 mb-8 pa-5">
        <v-card-text class="pa-5">
          <v-form ref="formEditInvestor" v-model="formEditInvestorValid">
            <RetailProfileData
              v-if="investorEditable.investor.type === 'RETAIL'"
              :investorEditable="investorEditable"
              :editable="editable"
            />
            <FundProfileData
              v-if="investorEditable.investor.type === 'FUND'"
              ref="fundProfileData"
              :investorEditable="investorEditable"
              :editable="editable"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-icon v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
          <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
          <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
        </v-card-actions>
      </v-card>

      <!-- INVESTOR WALLETS -->
      <v-card class="my-8">
        <div class="primary pa-3 d-sm-flex">
          <span>Carteras</span>
          <v-spacer />
          <div class="text-center d-sm-flex">
            <v-btn v-if="permissionsBannerActions.downloadWalletsReport.includes(user.role)" small @click="downloadWalletsReport()">
              Descargar
              <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="18" class="ml-1" />
            </v-btn>
            <BtnDepositFunds
              v-if="permissionsBannerActions.depositFunds.includes(user.role)"
              :investor="investorEditable"
              @getInvestorWallets="getInvestorWallets(), getTotalFundsInFavoriteCurrency()"
              class="mt-2 mt-sm-0 ml-sm-2"
            />
            <BtnWithdraw
              v-if="permissionsBannerActions.withdraw.includes(user.role)"
              :investor="investorEditable"
              :wallets="wallets"
              @getInvestorWallets="getInvestorWallets(), getTotalFundsInFavoriteCurrency()"
              class="mt-2 mt-sm-0 ml-sm-2"
            />
          </div>
        </div>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card rounded="xl" class="pa-3 mx-auto" max-width="300px">
                <p class="text-center font-weight-bold">{{ investorEditable.investor.favorite_currency }}</p>
                <p class="text-center text-subtitle-1 black--text mb-0">
                  Total:
                  <span class="font-weight-bold">
                    {{ currency(totalFundsInFavoriteCurrency.total_funds, { fromCents: true }) }}
                  </span>
                </p>
                <p class="text-center text-subtitle-1 black--text mb-0">
                  Disponible:
                  <span class="font-weight-bold">
                    {{ currency(totalFundsInFavoriteCurrency.available_funds, { fromCents: true }) }}
                  </span>
                </p>
              </v-card>
            </v-col>
            <v-col
              v-show="walletCurrency !== investorEditable.investor.favorite_currency"
              v-for="walletCurrency in walletsCurrencies()"
              :key="walletCurrency"
            >
              <v-card rounded="xl" class="pa-3">
                <p class="text-center font-weight-bold">{{ walletCurrency }}</p>
                <p class="text-center text-subtitle-1 black--text mb-0">
                  Total:
                  <span class="font-weight-bold">
                    {{ currency(totalFundsInFavoriteCurrency.total_funds * rates[walletCurrency], { fromCents: true }) }}
                  </span>
                </p>
                <p class="text-center text-subtitle-1 black--text mb-0">
                  Disponible:
                  <span class="font-weight-bold">
                    {{ currency(totalFundsInFavoriteCurrency.available_funds * rates[walletCurrency], { fromCents: true }) }}
                  </span>
                </p>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="wallets.length > 0" class="my-8">
            <v-col v-show="wallet" v-for="(wallet, i) in wallets" :key="i" cols="12" md="3">
              <v-card class="pb-5 secondary" rounded="lg">
                <v-card-title>
                  <span class="text-h5 font-weight-bold mx-auto">{{ wallet.currency }}</span>
                  <v-icon v-if="wallet.main_wallet" class="ml-n8" color="primary" title="Moneda favorita">star</v-icon>
                </v-card-title>
                <p v-if="wallet.office_id" class="text-center mb-0">Oficina: {{ geOfficeName(wallet.office_id) }}</p>
                <v-card-text class="text-center">
                  <p class="text-subtitle-1 mb-0">Total: {{ currency(wallet.total_funds, { fromCents: true }) }}</p>
                  <p class="text-subtitle-1 mb-0">Retenido: {{ currency(wallet.withheld_funds, { fromCents: true }) }}</p>
                  <p class="text-subtitle-1 mb-0">Disponible: {{ currency(wallet.available_funds, { fromCents: true }) }}</p>
                  <v-btn
                    color="primary"
                    :to="`/inversores/${investorEditable.investor.type.toLowerCase()}/${$route.params.id}/wallet/${wallet.id}`"
                    class="mt-3"
                  >
                    Ver transacciones
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <p v-else class="text-center mb-0 pa-3 text--secondary">No hay datos disponibles</p>
        </v-card-text>
      </v-card>

      <!-- INVESTOR INVESTMENTS -->
      <v-card class="my-8">
        <v-toolbar color="primary" dense>
          <span>Inversiones realizadas</span>
          <v-spacer />
          <div class="text-center d-sm-flex">
            <v-btn
              v-if="permissionsBannerActions.downloadInvestmentsReport.includes(user.role) && investments.length"
              small
              @click="downloadInvestmentsReport()"
            >
              Descargar
              <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="18" class="ml-1" />
            </v-btn>
          </div>
        </v-toolbar>

        <v-card-title>
          <v-text-field v-model="investmentSearch" append-icon="mdi-magnify" label="Buscar" single-line hide-details />
        </v-card-title>

        <v-data-table
          :headers="tableInvestmentsHeaders"
          :items="investments"
          :search="investmentSearch"
          :footer-props="
            investments.length > 100
              ? {
                  'items-per-page-options': [10, 20, 30, 50, 100]
                }
              : {}
          "
        >
          <template v-slot:[`item.project.id`]="{ item }">
            <router-link :to="`/proyectos/${item.project.id}`">{{ item.project.id }}</router-link>
          </template>
          <template v-slot:[`item.borrower.name`]="{ item }">
            <router-link :to="`/prestatarias/${item.borrower.id}`">{{ item.borrower.name }}</router-link>
          </template>
          <template v-slot:[`item.office.name`]="{ item }">
            <router-link :to="`/oficinas/${item.office.id}`">{{ item.office.name }}</router-link>
          </template>
          <template v-slot:[`item.investment_in_favorite_currency`]="{ item }">
            {{
              item.amount_reinvested > 0 && item.amount_new === 0 ? "-" : currency(item.amount / item.exchange_rate, { fromCents: true })
            }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ currency(item.amount, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.amount_custom_ml`]="{ item }">
            {{ currency(item.amount_invested, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.amount_repaid`]="{ item }">
            {{ currency(item.amount_repaid, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateL(item.created_at) }}
          </template>
          <template v-slot:[`item.consolidated_at`]="{ item }">
            {{ formatDateL(item.created_at) }}
          </template>
          <template v-slot:[`item.amount_new`]="{ item }">
            {{ currency(item.amount_new, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.amount_reinvested`]="{ item }">
            {{ currency(item.amount_reinvested, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.exchange_rate`]="{ item }">
            {{ item.amount_reinvested > 0 && item.amount_new === 0 ? "-" : item.exchange_rate }}
          </template>
          <template v-slot:[`item.daily_exchange_rate`]="{ item }">
            {{
              item.amount_reinvested > 0 && item.amount_new === 0
                ? "-"
                : getDefaultExchangeRate(investorEditable.investor.favorite_currency, item.currency).toFixed(6)
            }}
          </template>
          <template v-slot:[`item.last_payment_date`]="{ item }">
            {{ item.last_payment_date ? formatDateL(item.last_payment_date) : "-" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.status === 'INVESTABLE' || item.status === 'FUNDED'"
              color="red"
              @click="(investmentSelected = item), (dialogDeleteInvestment = true)"
            >
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <!-- INVESTOR WITHDRAWS -->
      <v-card class="my-8">
        <v-toolbar color="primary" dense>
          <span>Reembolsos</span>
          <v-spacer />
          <div class="text-center d-sm-flex">
            <v-btn
              v-if="permissionsBannerActions.downloadWithdrawsReport.includes(user.role) && withdraws.length"
              small
              @click="downloadWithdrawsReport()"
            >
              Descargar
              <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="18" class="ml-1" />
            </v-btn>
          </div>
        </v-toolbar>

        <v-data-table :headers="tableWithdrawsHeaders" :items="withdraws">
          <template v-slot:[`item.amount`]="{ item }">
            {{ currency(item.amount, { fromCents: true }) }}
          </template>
          <template v-slot:[`item.amount_custom`]="{ item }">
            {{ convertAmount(currency(item.amount, { fromCents: true }), item.exchange_rate) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateL(item.created_at) }}
          </template>
          <template v-slot:[`item.completed_at`]="{ item }">
            {{ formatDateL(item.completed_at) }}
          </template>
        </v-data-table>
      </v-card>

      <!-- IMAGES -->
      <v-row v-if="investorEditable.investor.type === 'RETAIL'">
        <!-- Form profile image -->
        <v-col cols="12" md="6">
          <v-card class="my-8">
            <v-toolbar color="primary" dense>Imagen de perfil</v-toolbar>
            <v-card-text class="pa-5">
              <v-form ref="formUploadProfileImage" v-model="formUploadProfileImageValid">
                <LazyImage :img="this.profileImageUrl ? this.profileImageUrl : ''" :lazy="lazyProfileImage" />
                <v-file-input
                  v-model="profileImage"
                  :accept="['.jpeg', '.png', '.jpg']"
                  truncate-length="100"
                  :clearable="false"
                  prepend-icon="image"
                  label="Imagen de perfil"
                  :rules="formRules.imageRequiredRules"
                />
              </v-form>
            </v-card-text>
            <v-card-actions v-if="permissionsCardsActions.profileImage.edit.includes(user.role)" class="justify-end">
              <v-btn text :disabled="profileImage === null" @click="uploadProfileImage()">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Form identity document images -->
        <v-col cols="12" md="6">
          <v-card class="my-8">
            <v-toolbar color="primary" dense>
              <p class="mb-0">
                Documento de indentidad
                <v-icon
                  v-if="investorEditable.completed_steps && investorEditable.completed_steps.includes('IDENTITY_DOCUMENT_VERIFIED')"
                  dark
                >
                  verified_user
                </v-icon>
              </p>
              <v-spacer />
              <v-btn
                v-if="
                  permissionsCardsActions.verifyUserIdentityDocument.includes(user.role) &&
                  investorEditable.completed_steps &&
                  !investorEditable.completed_steps.includes('IDENTITY_DOCUMENT_VERIFIED') &&
                  investorEditable.identity_document_images
                "
                small
                @click="verifyUserIdentityDocument(investorEditable.id)"
              >
                <span>Verificar</span>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
              <div class="text-center mb-5">
                <a v-if="indentityDocumentFrontImageUrl" :href="indentityDocumentFrontImageUrl" target="_blank" class="black--text">
                  <u>Ver anverso del documento de identidad</u>
                </a>
                <span v-else>Anverso del documento de identidad</span>
              </div>
              <v-file-input
                v-model="indentityDocumentFrontImage"
                outlined
                dense
                accept="image/*, .pdf"
                truncate-length="100"
                :clearable="false"
                prepend-icon="attach_file"
                label="Documento de identidad (anverso)"
              />
            </v-card-text>
            <v-card-text class="pa-5">
              <div class="text-center mb-5">
                <a v-if="indentityDocumentBackImageUrl" :href="indentityDocumentBackImageUrl" target="_blank" class="black--text">
                  <u>Ver reverso del documento de identidad</u>
                </a>
                <span v-else>Reverso del documento de identidad</span>
              </div>
              <v-file-input
                v-model="indentityDocumentBackImage"
                outlined
                dense
                accept="image/*, .pdf"
                truncate-length="100"
                :clearable="false"
                prepend-icon="attach_file"
                label="Documento de identidad (reverso)"
              />
            </v-card-text>
            <v-card-actions v-if="permissionsCardsActions.indentityDocumentImages.edit.includes(user.role)" class="justify-end">
              <v-btn
                text
                @click="uploadIndentityDocumentImages"
                :disabled="indentityDocumentFrontImage === null && indentityDocumentBackImage === null"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- INVESTOR NOTES -->
      <v-card class="my-8">
        <v-toolbar color="primary" dense>Notas</v-toolbar>
        <v-card-text class="pa-0">
          <p v-if="!editNotes" class="black--text pa-5" v-html="notesContent" />
          <Tiptap v-else :parentContent="notesContent" @saveNotesChanges="saveNotesChanges" @cancelNotesChanges="editNotes = false" />
        </v-card-text>
        <v-card-actions v-if="permissionsCardsActions.addNote.includes(user.role) && !editNotes" class="justify-end">
          <v-btn icon @click="editNotes = true">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Dialog delete investment -->
      <DialogBox :model="dialogDeleteInvestment" :color="'error'" isdark>
        <template slot="toolbar">
          <span>¿Estás seguro de eliminar esta inversión?</span>
        </template>
        <template slot="actions">
          <v-btn text @click="dialogDeleteInvestment = false">Cerrar</v-btn>
          <v-btn text @click="(dialogDeleteInvestment = false), deleteInvestment()">eliminar</v-btn>
        </template>
      </DialogBox>
    </div>
    <v-card v-else height="87vh" class="d-flex justify-center align-center">
      <v-btn v-if="!investorEditable.completed_steps" @click="$store.dispatch('investors/verifyUserEmail', investorEditable.id)">
        Verify email
      </v-btn>
      <p v-else>El usuario debe completar su registro con los datos necesarios</p>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import listeners from "@/firebase/listeners";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import LazyImage from "@/components/elements/LazyImage";
import DialogBox from "@/components/elements/DialogBox";
import Tiptap from "@/components/elements/Tiptap.vue";
import BtnWithdraw from "@/components/elements/investor/BtnWithdraw.vue";
import RetailProfileData from "@/components/elements/investor/RetailProfileData.vue";
import FundProfileData from "@/components/elements/investor/FundProfileData.vue";
import BtnDepositFunds from "@/components/elements/investor/BtnDepositFunds.vue";
import BtnManageAutomaticReinvestment from "@/components/elements/investor/BtnManageAutomaticReinvestment.vue";
import BtnInvestProjectBag from "@/components/elements/investor/BtnInvestProjectBag.vue";
import * as currency from "currency.js";

export default {
  name: "Investor",
  components: {
    Banner,
    LazyImage,
    DialogBox,
    Tiptap,
    BtnWithdraw,
    RetailProfileData,
    FundProfileData,
    BtnDepositFunds,
    BtnManageAutomaticReinvestment,
    BtnInvestProjectBag
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      // Banner actions permissions
      permissionsBannerActions: {
        acceptInvestorConditions: ["SUPERADMIN", "ADMIN"],
        verifyEmail: ["SUPERADMIN", "ADMIN"],
        depositFunds: ["SUPERADMIN", "ADMIN"],
        withdraw: ["SUPERADMIN", "ADMIN"],
        downloadWalletsReport: ["SUPERADMIN", "ADMIN"],
        downloadInvestmentsReport: ["SUPERADMIN", "ADMIN"],
        downloadWithdrawsReport: ["SUPERADMIN", "ADMIN"],
        toInvest: ["SUPERADMIN", "ADMIN"],
        downloadReport: ["SUPERADMIN", "ADMIN"]
      },

      // Investor investments datatable
      tableInvestmentsHeaders: [
        { text: "Proyecto", value: "project.id", sortable: false },
        { text: "Estado", value: "project.status", sortable: false },
        { text: "Prestataria", value: "borrower.name", sortable: false },
        { text: "Oficina", value: "office.name", sortable: false },
        { text: "Inversión MF", value: "investment_in_favorite_currency", sortable: false },
        { text: "Depósito inversor ML", value: "amount", sortable: false },
        { text: "Inversión ML", value: "amount_custom_ml", sortable: false },
        { text: "Recuperado en ML", value: "amount_repaid", sortable: false },
        { text: "Fecha inversión", value: "created_at" },
        { text: "Fecha cheque", value: "consolidated_at" },
        { text: "Dinero nuevo ML", value: "amount_new", sortable: false },
        { text: "Reinversión ML", value: "amount_reinvested", sortable: false },
        { text: "TC", value: "exchange_rate", sortable: false },
        { text: "TC (día)", value: "daily_exchange_rate", sortable: false },
        { text: "Última fecha de pago", value: "last_payment_date", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],

      tableWithdrawsHeaders: [
        { text: "Oficina", value: "office.name" },
        { text: "Moneda", value: "from_currency" },
        { text: "Cantidad divisa", value: "amount" },
        { text: "TC", value: "exchange_rate" },
        { text: "Cantidad neta", value: "amount_custom", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Fecha de solicitud", value: "created_at" },
        { text: "Fecha de envío", value: "completed_at" }
      ],

      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN"]
        },
        profileImage: {
          edit: ["SUPERADMIN", "ADMIN"]
        },
        indentityDocumentImages: {
          edit: ["SUPERADMIN", "ADMIN"]
        },
        verifyUserIdentityDocument: ["SUPERADMIN", "ADMIN"],
        verifyEmail: ["SUPERADMIN", "ADMIN"],
        addNote: ["SUPERADMIN", "ADMIN"],
        deleteInvestment: ["SUPERADMIN", "ADMIN"]
      },

      // Form edit investor
      editable: false,
      formEditInvestorValid: false,
      areGeneralConditionsAccepted: false,
      generalConditionsDate: "",
      investorEditable: {},

      // Form upload profile image
      formUploadProfileImageValid: false,
      profileImage: null,
      profileImageUrl: "",
      lazyProfileImage: false,

      // Form upload identity document images
      indentityDocumentFrontImage: null,
      indentityDocumentBackImage: null,
      indentityDocumentFrontImageUrl: "",
      indentityDocumentBackImageUrl: "",
      lazyIndentityDocumentFrontImage: false,
      lazyIndentityDocumentBackImage: false,

      // Dialogs
      dialogDeleteInvestment: false,

      // Tiptap notes editor
      notesContent: "",
      editNotes: false,

      // Wallets
      totalFundsInFavoriteCurrency: 0,
      wallets: [],

      // Repayment selected to edit/delete
      investmentSearch: "",
      investmentSelected: null
    };
  },
  computed: {
    ...mapState({
      investor: (state) => state.investors.investor,
      user: (state) => state.user.user,
      commissions: (state) => state.investments.commissions,
      investments: (state) => state.investments.investments,
      withdraws: (state) => state.investors.withdraws,
      offices: (state) => state.offices.offices,
      rates: (state) => state.currencies.rates
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  watch: {
    async investor() {
      await this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.investor(this.$route.params.id);
    if (!this._.isEmpty(this.investor)) {
      // Push back if id does not exist
      if (this.investor?.id !== this.$route.params.id) {
        if (this.investor?.type === "RETAIL") this.$router.push("/inversores/retail");
        else if (this.investor?.type === "FUND") this.$router.push("/inversores/fund");
        else this.$router.push("/");
        this.$store.commit("SET_LOADING", false);
      } else {
        await this.getInvestmentsSettings();
        await this.$store.dispatch("currencies/getCurrencies");
        await this.$store.dispatch("offices/getOffices");
        await this.$store.dispatch("investments/getInvestments", {
          filter: { "investor.id": this.$route.params.id },
          sort: { created_at: "desc" }
        });
        await this.$store.dispatch("investors/getWithdraws", {
          filter: { "investor.id": this.$route.params.id },
          sort: { created_at: "DESC" }
        });
        await this.formatFields();
        await this.getInvestorWallets();
        await this.getTotalFundsInFavoriteCurrency();
      }
    } else this.$router.push("/");
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      editRetailInvestor: "investors/editRetailInvestor",
      editFundInvestor: "investors/editFundInvestor",
      getInvestmentsSettings: "investments/getInvestmentsSettings",
      verifyUserIdentityDocument: "investors/verifyUserIdentityDocument",
      verifyUserEmail: "investors/verifyUserEmail",
      acceptInvestorConditions: "investors/acceptInvestorConditions",
      addUserIdentityDocument: "investors/addUserIdentityDocument"
    }),

    // Format fields editables
    async formatFields() {
      this.investorEditable = this._.cloneDeep(this.investor);
      if (this.investorEditable.notes) this.notesContent = this.investorEditable.notes;

      if (this.investorEditable?.investor.type === "RETAIL") {
        if (!this.investorEditable.address) {
          this.investorEditable.address = {
            coordinates: {
              lat: 0,
              lon: 0
            },
            name: ""
          };
        }
        if (this.investorEditable.photo) this.profileImageUrl = await this.getFileFromStorage(this.investorEditable.photo);
        if (this.investorEditable.identity_document_images) {
          if (this.investorEditable.identity_document_images.front)
            this.indentityDocumentFrontImageUrl = await this.getFileFromStorage(this.investorEditable.identity_document_images.front);
          if (this.investorEditable.identity_document_images.back)
            this.indentityDocumentBackImageUrl = await this.getFileFromStorage(this.investorEditable.identity_document_images.back);
        }

        this.checkGeneralConditionsAccepted();
      }
    },

    // Edit investor
    async edit() {
      if (this.$refs.formEditInvestor.validate()) {
        let investorEditableFormattedData = {
          id: this.investorEditable.id,
          first_name: this.investorEditable.first_name,
          identity_document: this.investorEditable.identity_document,
          country: this.investorEditable.country,
          investor: {}
        };

        const exchange_rates = {};

        if (this.investorEditable?.investor.type === "RETAIL") {
          investorEditableFormattedData.last_name = this.investorEditable.last_name;
          investorEditableFormattedData.address = this.investorEditable.address;
          investorEditableFormattedData.city = this.investorEditable.city;
          investorEditableFormattedData.email = this.investorEditable.email;
          investorEditableFormattedData.nationality = this.investorEditable.nationality;
          investorEditableFormattedData.phone = this.investorEditable.phone;
          investorEditableFormattedData.zone = this.investorEditable.zone;
          investorEditableFormattedData.investor.professional_activity = this.investorEditable.investor.professional_activity;

          investorEditableFormattedData.identity_document_expiration_date = this.formatDateToISO8601(
            this.investorEditable.identity_document_expiration_date
          );
          investorEditableFormattedData.date_of_birth = this.formatDateToISO8601(this.investorEditable.date_of_birth);
        } else if (this.investorEditable?.investor.type === "FUND") {
          delete investorEditableFormattedData.investor;
          const exchangeRatesAux = [...this.$refs.fundProfileData.$data.arrayExchangeRates];

          for (let i = 0; i < exchangeRatesAux.length; i++) {
            const exchangeRate = exchangeRatesAux[i];
            exchange_rates[exchangeRate.partner.id] = exchangeRate.value;
          }

          for (let i = 0; i < Object.keys(this.investorEditable.investor.exchange_rates).length; i++) {
            const partnerId = Object.keys(this.investorEditable.investor.exchange_rates)[i];
            if (!exchangeRatesAux.some((e) => e.partner.id === partnerId)) exchange_rates[partnerId] = 0;
          }
        }

        if (this.notesContent) investorEditableFormattedData.notes = this.notesContent;
        if (this.investorEditable.password) investorEditableFormattedData.password = this.investorEditable.password;

        if (this.investorEditable?.investor.type === "RETAIL") {
          await this.editRetailInvestor(investorEditableFormattedData);
        } else if (this.investorEditable?.investor.type === "FUND") {
          await this.editFundInvestor(investorEditableFormattedData);
        }

        if (Object.keys(exchange_rates).length) {
          await this.$store.dispatch("investors/editFundInvestorExchangeRates", { investor_id: this.investorEditable.id, exchange_rates });
        }

        await this.formatFields();
        this.editable = false;
      }
    },

    // Get wallets of investor
    async getInvestorWallets() {
      this.wallets = await this.$store.dispatch("investors/getInvestorWallets", this.investorEditable.id);
    },

    // Valid and upload image of investor's profile
    async uploadProfileImage() {
      if (this.$refs.formUploadProfileImage.validate()) {
        const user_id = this.investorEditable.id;
        const file = await this.toBase64(this.profileImage);

        await this.$store.dispatch("addUserPhoto", { user_id, file });
        this.lazyProfileImage = true;
        this.profileImage = null;
        this.$refs.formUploadProfileImage.resetValidation();
      }
    },

    // Valid and upload identity document images
    async uploadIndentityDocumentImages() {
      const user_id = this.investorEditable.id;

      if (this.indentityDocumentFrontImage != null) {
        const file = await this.toBase64(this.indentityDocumentFrontImage);

        await this.addUserIdentityDocument({ user_id, file, orientation: "front" });
        this.lazyIndentityDocumentFrontImage = true;
        this.indentityDocumentFrontImage = null;
      }

      if (this.indentityDocumentBackImage != null) {
        const file = await this.toBase64(this.indentityDocumentBackImage);

        await this.addUserIdentityDocument({ user_id, file, orientation: "back" });
        this.lazyIndentityDocumentBackImage = true;
        this.indentityDocumentBackImage = null;
      }
    },

    // Save and upload notes changes
    async saveNotesChanges(value) {
      this.notesContent = value;
      await this.edit();
      this.editNotes = false;
    },

    async deleteInvestment() {
      await this.$store.dispatch("investments/deleteInvestment", this.investmentSelected.id);
      await this.$store.dispatch("investments/getInvestments", { filter: { "investor.id": this.$route.params.id } });
    },

    geOfficeName(officeId) {
      return this.offices.find((e) => e.id === officeId)?.name;
    },

    // Return if investor accepts the current general conditions
    async checkGeneralConditionsAccepted() {
      const investorGeneralConditionsDate = this.investorEditable.investor?.general_conditions_date;
      const { data } = await this.$store.dispatch("settings/getGeneralConditions");
      this.generalConditionsDate = data.data.date;
      this.areGeneralConditionsAccepted = !investorGeneralConditionsDate || investorGeneralConditionsDate < data.data.date;
    },

    // Generate and download wallets transactions
    async downloadWalletsReport() {
      const { data: path } = await this.$store.dispatch("investors/generateWalletsReport", this.investorEditable.id);
      window.open(path, "_blank");
    },

    // Generate and download wallets transactions
    async downloadInvestmentsReport() {
      const { data: path } = await this.$store.dispatch("investors/generateInvestmentsReportByInvestor", this.investorEditable.id);
      window.open(path, "_blank");
    },

    // Generate and download wallets transactions
    async downloadWithdrawsReport() {
      const { data: path } = await this.$store.dispatch("investors/generateWithdrawsReportByInvestor", this.investorEditable.id);
      window.open(path, "_blank");
    },

    async getTotalFundsInFavoriteCurrency() {
      this.totalFundsInFavoriteCurrency = await this.$store.dispatch("investors/getTotalFunds", {
        investor_id: this.investorEditable.id,
        currency: this.investorEditable.investor.favorite_currency
      });
    },

    walletsCurrencies() {
      const walletsCurrencies = [];

      for (let i = 0; i < this.wallets.length; i++) {
        const wallet = this.wallets[i];
        if (!walletsCurrencies.includes(wallet.currency)) walletsCurrencies.push(wallet.currency);
      }

      return walletsCurrencies;
    },

    async downloadFundInvestorProjects() {
      const { data: path } = await this.$store.dispatch("projects/downloadFundInvestorProjects", this.investorEditable.id);
      window.open(path, "_blank");
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;
    }
  }
};
</script>
